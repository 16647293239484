import React from 'react';
import { graphql } from 'gatsby';
import {
  HomeHero,
  HomeLatestProperties,
  HomeWhoWeAre,
  HomeLocations,
  HomeInstantValuation,
  HomeFooter,
  HomeGetAdvice


} from 'components/sections';

import Layout from '../components/layout';
import { ViewportEnterAnimation, NewHomesHomeBadge } from 'components/ui';
import SEO from '../components/seo';
import {indexPageMotion, indexScrollState, functionProperties, functionWhoWeAre, functionHomeInstantValuation } from 'motion/index.js';
import { functionAboutContact, functionSlider, functionGetAdvice } from 'motion/new-homes.js';

import GoogleMapReact from 'google-map-react';  
   const center = {
      lat: 51.50975,
      lng: -0.017595
    }
  const  zoom = 12;
  const key = 'AIzaSyCuS5Z05YuKuDXjDrLkjFC1a9HzxeIjJXw';



indexScrollState();

const IndexPage = ({
  data: {
    pages: { homepage, propertySearchForm},
  },


}) =>{

    let contactFooter = {join:{},refer:{}};

  contactFooter.join.img = "img";
  contactFooter.join.title= "img";
  contactFooter.join.description= "img";
  contactFooter.refer.title= "img";
  contactFooter.refer.img= "img";
  contactFooter.refer.description= "img"; 

  const mockData = {
   getAdvice: {
    title: 'Help to Buy',
    content: 'The government has created the Help to Buy scheme to help you buy your own home with just a 5% deposit.',
    buttonText: 'Find out more',
  },
};



return(
  <Layout onVisible={indexPageMotion}>
    <SEO title={homepage.seo.title} description={homepage.seo.meta} keywords={homepage.seo.keywords} />
    <HomeHero content={homepage} propertySearchForm={propertySearchForm} />
    <ViewportEnterAnimation offset={100} animation={functionProperties}>
      <HomeLatestProperties content={homepage.slider} />
    </ViewportEnterAnimation>
    <ViewportEnterAnimation offset={100} animation={functionWhoWeAre}>
      <HomeWhoWeAre content={homepage} />
    </ViewportEnterAnimation>
    <HomeLocations content={homepage} />


    <NewHomesHomeBadge />

    <ViewportEnterAnimation offset={100} animation={functionGetAdvice}>
      <HomeGetAdvice content={homepage.contactFooter.helptobuy} />
    </ViewportEnterAnimation>


    <ViewportEnterAnimation offset={100} animation={functionAboutContact}>
      <HomeFooter content={homepage.contactFooter} />
    </ViewportEnterAnimation>

    <div style={{ display: 'none' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: key,  libraries: ['places'],}}
            defaultCenter={center}
            defaultZoom= {zoom}
            yesIWantToUseGoogleMapApiInternals
          >
          </GoogleMapReact>
        </div>
  </Layout>
)};

export const query = graphql`
  query IndexPage {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      homepage {
        landing {
          title
          bodyText
          img
        }
        slider {
          title
          subtitle
          options
        }
        whoWeAre {
          title
          bodyText
          btn
          img1
          img2
          img1alt
          img2alt
        }
        offices {
          description
          callToAction
        }
        about {
          branches {
            number
            text
          }
          languages {
            number
            text
          }
          offices {
            number
            text
          }
          homes {
            number
            text
          }
        }
        callToAction {
          title
          btn
          img
        }
        contactFooter{
         helptobuy{
            title
            content
            buttonText
          }
          join{
            img
            title
            description
          }
          refer{
            img
            title
            description
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;

export default IndexPage;
